<template>
  <div>
    <div class="container-fluid plr-80 payment-page">
      <div class="mb-5">
        <BookingEngineDateSelector />
      </div>

      <div class="card my-3">
        <div class="card-body px-md-5">
          <div
            class="tabs d-flex justify-content-between align-items-center py-3"
          >
            <div class="tab-section completed w-100">
              <div class="tab-icon"></div>
            </div>
            <div class="tab-section w-100" :class="{ 'current': step === 'extra', 'completed': step === 'summary' }">
              <p v-if="step === 'extra'" class="tab-label">Extra</p>
              <div class="tab-icon"></div>
            </div>
            <div class="tab-section w-100" :class="{ 'current': step === 'summary' }">
              <p v-if="step === 'summary'" class="tab-label">Summary</p>
              <div class="tab-icon"></div>
            </div>
            <div class="tab-section">
              <div class="tab-icon"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 'extra'">
        <div class="extra-list mt-5">
          <div class="card my-4 extra">
            <div class="card-body row px-md-5">
              <div class="col-md-4">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <img
                      src="../assets/images/newspaper.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div>Newspaper</div>
                </div>
              </div>
              <div class="col-md-3 my-auto">For Per Item</div>
              <div class="text-main font-weight-bold col-md-2 my-auto">
                EUR 2.40
              </div>
              <div class="col-md-3 text-right my-auto">
                <button class="btn btn-main px-5">+ Add</button>
              </div>
            </div>
          </div>
          <div class="card my-4 extra">
            <div class="card-body row px-md-5">
              <div class="col-md-4">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <img
                      src="../assets/images/pet.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div>Per Night</div>
                </div>
              </div>
              <div class="col-md-3 my-auto">For Per Item</div>
              <div class="text-main font-weight-bold col-md-2 my-auto">
                EUR 10
              </div>
              <div class="col-md-3 text-right my-auto">
                <button class="btn btn-main px-5">+ Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="step === 'summary'">
        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <div class="row">
              <div class="col-md-6 py-2">
                <h5 class="mb-4">Your reservation</h5>
                <table class="table custom-table">
                  <tr>
                    <td>Total number of rooms</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>Total number of persons</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>Nights</td>
                    <td>1</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-6 py-2">
                <h5 class="mb-4">Travel Period</h5>
                <table class="table custom-table">
                  <tr>
                    <td>Check in</td>
                    <td>Mon, 11 Mar 2024</td>
                  </tr>
                  <tr>
                    <td>Check out</td>
                    <td>Tue, 12 Mar 2024</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <table class="table">
              <tr>
                <th>No. of rooms</th>
                <th>Total price</th>
                <th>Persons per room</th>
                <th>Meal type</th>
                <th>Price per room</th>
                <th>Total</th>
                <th>Remove</th>
              </tr>
              <tr>
                <td>1x</td>
                <td>
                  <span>Apartment 2 - non refundable rate</span><br />
                  <span class="text-main"
                    >Rate details | Cancellation policy</span
                  >
                </td>
                <td class="text-center">
                  <img
                    v-for="i in 2"
                    :key="i"
                    src="../assets/icons/person.svg"
                    class="mr-1"
                    alt="person"
                  />
                </td>
                <td>No meal Included</td>
                <td>
                  <span class="text-main">EUR 135.00</span>
                </td>
                <td>
                  <span class="text-main">EUR 135.00</span>
                </td>
                <td class="text-center">
                  <img src="../assets/icons/trash.svg" class="mr-1" alt="trash" />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <h5 class="mb-4">Your details</h5>
            <div class="row">
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="First name*"
                />
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Last name*"
                />
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Email address*"
                />
              </div>
              <div class="col-md-5 my-3 no-border">
                <vue-tel-input></vue-tel-input>
              </div>
              <div class="col-md-4 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Mandatory post code*"
                />
              </div>
              <div class="col-md-4 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="City*"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <h5 class="mb-4">Guest details</h5>
            <div class="row">
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="First name*"
                />
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Last name*"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <div class="d-flex justify-content-between">
              <h5 class="mb-4">Company details (Optional)</h5>
              <div>
                <button
                  @click="showCompanyDetails = !showCompanyDetails"
                  class="btn btn-outline-main px-5"
                >
                  Open
                  <img src="../assets/icons/open.svg" class="mr-1" alt="open" />
                </button>
              </div>
            </div>
            <div class="row" v-if="showCompanyDetails">
              <div class="col-md-10 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Company name"
                />
              </div>
              <div class="col-md-10 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Address 1"
                />
              </div>
              <div class="col-md-10 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Address 2 (Optional)"
                />
              </div>
              <div class="col-md-3 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="City"
                />
              </div>
              <div class="col-md-3 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Mandatory post code"
                />
              </div>
              <div class="col-md-3 my-3">
                <select class="form-control no-border" placeholder="Country">
                  <option value="Nigeria">Nigeria</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <div class="d-flex justify-content-between">
              <h5 class="mb-4">Special requests (Optional)</h5>
              <div>
                <button
                  @click="showSpecialRequest = !showSpecialRequest"
                  class="btn btn-outline-main px-5"
                >
                  Open
                  <img src="../assets/icons/open.svg" class="mr-1" alt="open" />
                </button>
              </div>
            </div>
            <div class="row" v-if="showSpecialRequest">
              <div class="col-md-10 my-3">
                <textarea
                  class="form-control no-border"
                  placeholder="Do you have any special requests / wishes?"
                  rows="7"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <h5 class="mb-4">Credit card details</h5>
            <p>
              Your credit card is only required to guarantee your reservation.
              Payment is made directly at the accommodation.
            </p>
            <div class="row">
              <div class="col-md-9 my-3">
                <div class="row">
                  <div class="col-md-6 my-3">
                    <select class="form-control no-border light-bg">
                      <option value="">Choose your card type</option>
                    </select>
                  </div>
                  <div class="col-md-6 my-3">
                    <input
                      class="form-control no-border light-bg"
                      type="text"
                      placeholder="****_****_****_****"
                    />
                  </div>
                  <div class="col-md-3 my-3">
                    <select class="form-control no-border light-bg">
                      <option value="">Year</option>
                    </select>
                  </div>
                  <div class="col-md-3 my-3">
                    <select class="form-control no-border light-bg">
                      <option value="">Month</option>
                    </select>
                  </div>
                  <div class="col-md-12 col-offset-6 my-3">
                    <input
                      class="form-control no-border light-bg"
                      type="text"
                      placeholder="Name on card"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 px-3">
                <img
                  src="../assets/images/pci-booking.svg"
                  class="img-fluid"
                  alt="PCI-Booking"
                />
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="my-1">
          <input type="checkbox" class="mr-1" />
          With this booking I confirm the
          <a href="#" class="text-main">Terms & Conditions</a> and the
          <a href="#" class="text-main">Privacy Policy (GDPR)</a>
        </div>
      </div>

      <BookingCheckout
        :sticky="false"
        :buttonText="step === 'extra' ? 'Summary' : 'Book Now'"
        @proceed="proceedWithBooking"
      />
    </div>
  </div>
</template>
<script>
import BookingCheckout from "./Widgets/BookingCheckout.vue";
import BookingEngineDateSelector from "./Widgets/BookingEngineDateSelector.vue";
export default {
  name: "Reservation",
  components: {
    BookingCheckout,
    BookingEngineDateSelector,
  },
  data() {
    return {
      step: 'extra',
      showCompanyDetails: false,
      showSpecialRequest: false,
    };
  },
  methods: {
    proceedWithBooking() {
      if (this.step === 'extra') {
        this.step = 'summary';
      } else if (this.step === 'summary') {
        this.$router.push(`/reservation_success`)
      }
    }
  }
};
</script>
